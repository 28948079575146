<template>
  <div clas="container">
    <h2>{{ $t("register.signIn.title") }}</h2>
    <div class="flix-form-group">
      <inputText
        :error="getError('username')"
        :label="$t('register.signIn.email')"
        :placeholder="$t('register.signIn.email')"
        :data="username"
        :callback="setUsername"
      />
      <inputPassword
        :error="getError('pass')"
        :label="$t('register.password.title')"
        :placeholder="$t('register.password.title')"
        :data="pass"
        :callback="setPassword"
      />
    </div>
    <div class="flix-form-group">
      <saveBtn
        class="saveBtn"
        :label="$t('register.signIn.button')"
        :callback="function () { setSave() }"
      />
      <div
        v-html="state"
        class="flix-text-danger flix-html-strong flix-html-small"
        v-if="state"
      />
    </div>
    <a
      href="#"
      class="flix-html-a flix-text-darkgrey"
      @click.prevent="callback('register')"
      >{{ $t("register.isNotRegistred") }}</a
    >
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data() {
    return {
      username: this.getUsername(),
      pass: "",
      send: false,
      state: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getUsername() {
      if (
        typeof this.$store.state.save.userData === "undefined" ||
        !this.$store.state.save.userData
      ) {
        return "";
      }
      if (typeof this.$store.state.save.userData.username === "string") {
        return this.$store.state.save.userData.username;
      }
    },
    getError(type) {
      if (!this.send) {
        return false;
      }
      if (!this[type]) {
        return this.$t("register.warning");
      }
    },
    setPassword(val) {
      this.pass = val;
      this.error = false;
    },
    setUsername(val) {
      this.username = val.toLowerCase().trim();
      this.error = false;
    },
    setSave() {
      this.send = true;
      this.username = this.username.toLowerCase().trim();

      if (!this.username) {
        return false;
      }
      if (!this.pass) {
        return false;
      }

      this.$store.commit("save/setUser", {
        username: this.username,
        pass: this.pass
      });

      this.$store.dispatch(
        "save/setSignIn",
        function(state, result) {
          if (state) {
            this.$store.commit("save/setSuccess");
          }
          this.$store.dispatch("save/setUpdate", [state, result]);
        }.bind(this)
      );
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.container
  a.btn
    display: block
    font-size: 10pt
    text-align: center
    margin-bottom: 10px
    color: $dark-grey
  a.saveBtn
    margin-bottom: 10px
    display: flex
  h3
    text-align: center
</style>
